<template>
	<no-auth>
		<div class="container">
			<el-card>
				<div style="line-height: 20px;color: #666;">
					<div>当员工删除客户时，会在员工删人提醒列表中产生一条记录，开启「删人通知」后，选择接收通知的员工会收到一条推送</div>
					<div>
						该功能仅监测企店助手应用范围内的员工，您可以前往企业微信设置应用的可见范围。
						<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccn91L6lhB6RLaAevJjx6hFNu"
						target="_blank">如何设置可见范围？</el-link>
					</div>
				</div>
			</el-card>

			<el-card style="margin-top: 15px;">
				<div class="search">
					<div class="search-item">
						<span class="search-title">关键字:</span>
						<el-select v-model="value" placeholder="请选择" style="width: 130px;margin-right: 5px;">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
						<el-input :placeholder="value === 0 ? '客户微信昵称':'客户手机号'" v-model="keywords" style="width: 200px;margin-right: 10px;"></el-input>
					</div>
					<div class="search-item">
						<span class="search-title">操作员工:</span>
						<el-select 
							v-model="keyValue" 
							placeholder="请选择员工" 
							multiple
							filterable
							style="width: 250px;margin-right: 5px;">
							<el-option v-for="item in keyList" :key="item.Id" :label="item.EmployeeName" :value="item.Id">
							</el-option>
						</el-select>
					</div>
					<div class="search-item">
						<span class="search-title">删除时间:</span>
						<el-date-picker v-model="time" style="width: 250px;" type="daterange" range-separator="~"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
						</el-date-picker>
					</div>
					<div class="search-item">
						<div>
							<el-button type="primary" style="margin-right: 15px;" @click="pageIndex=1;getList()">查询</el-button>
						</div>
						<div>
							<el-button type="primary" plain @click="visible=true">删人通知</el-button>
						</div>
					</div>
				</div>

				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="EmployeeName" label="操作员工">
					</el-table-column>
					<el-table-column prop="EmployeePhone" label="员工手机号">
					</el-table-column>
					<el-table-column prop="date" label="被删客户">
						<template slot-scope="scope">
							<div class="user-info">
								<img class="hd" :src="scope.row.WxHeadUrl||'https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png'">
								<div class="info">
									<div class="name">{{scope.row.WxNickname}}</div>
									<div class="phone" v-if="scope.row.Phone">{{scope.row.Phone}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AddTime" label="删除时间">
					</el-table-column>
					<el-table-column prop="AddFriendsTime" label="添加时间">
					</el-table-column>
					<el-table-column prop="AddDays" label="添加时长">
						<template slot-scope="scope">
							<div>{{scope.row.AddDays}}天</div>
						</template>
					</el-table-column>
					<el-table-column prop="control" label="操作" width="80">
						<template slot-scope="scope">
							<el-button type="text" @click="handleToDetail(scope.row)">详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination">
					<div class="left">
					</div>
					<el-pagination :page-size="pageSize" :total="total" :current-page="pageIndex"
						:page-sizes="[10, 20, 30, 40, 50]" @size-change="sizeChange" @current-change="currentChange"
						layout="total, sizes, prev, pager, next, jumper">
					</el-pagination>
				</div>

			</el-card>

			<deleteNoticeEmployee :visible="visible" @close="visible=false" @reload="getList" :employeeList="keyList"></deleteNoticeEmployee>

		</div>
	</no-auth>
</template>

<script>
	import deleteNoticeEmployee from './components/deleteNoticeEmployee.vue';
	import {
		employeedeletecustomerreminder,
		employeelistbystate
	} from '@/api/sv1.0.0.js';
	import api from '@/api/other.js'
	
	import noAuth from './components/deleteNoAuth.vue'

	export default {
		components: {
			deleteNoticeEmployee,
			noAuth
		},
		data() {
			return {
				
				options: [{
					value: 0,
					label: '微信昵称'
				},{
					value: 1,
					label: '手机号'
				}],
				value: 0,
				
				keyList: [],
				keyValue: [],
				
				keywords: '',
				time: null,

				tableData: [],


				visible: false,

				pageIndex: 1,
				pageSize: 20,
				total: 0
			};
		},
		beforeMount() {
			this.getList();
			this.getEmployeeList();
		},
		methods: {
			async getEmployeeList(){
				try{
					const res = await employeelistbystate({EmployeeState: 1});
					this.keyList = res.Result;
				}catch(e){
				}
			},
			async getList() {
				try {
					const res = await employeedeletecustomerreminder({
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize,
						KeywordsType: this.value,
						Keywords: this.keywords,
						EmployeeIdList: this.keyValue,
						StartTime: this.time ? this.time[0] : '',
						EndTime: this.time ? this.formatNextDate(this.time[1]) : ''
					})

					if (res.IsSuccess) {
						this.tableData = res.Result.Results;
						this.total = res.Result.Total;
					}
				} catch (e) {}
			},
			//将天数+1
			formatNextDate(dateStr){
				var date = dateStr.split('-');
				var year = date[0];
				var month = +date[1] - 1;
				var day = +date[2];
				
				return this.getTimeStr(new Date(year, month,day+1))
			},
			//将时间转换为字符串类型
			getTimeStr(date) {
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
			
				return [
					year,
					month > 9 ? month : ('0' + month),
					day > 9 ? day : ('0' + day),
				].join('-')
			},
			sizeChange(value) {
				this.pageSize = value;
				this.getList();
			},
			currentChange(index) {
				this.pageIndex = index;
				this.getList()
			},
			handleToDetail(record){
				this.$router.push({
					name: 'CustomerDetail',
					query: {
						Id: record.Id
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.container {
		font-size: 14px;

		.search {
			position: relative;
			margin-bottom: 15px;

			display: flex;
			flex-wrap: wrap;

			.search-item {
				margin-bottom: 10px;
				margin-right: 15px;
				display: flex;
				
				.search-title{
					padding-top: 10px;
					margin-right: 5px;
					font-size: 14px;
					color: #606266;
					font-weight: bold;
				}
			}

			.help {
				position: absolute;
				right: 0;
				top: 5px;
				white-space: nowrap;
				color: #666;
				cursor: pointer;
			}
		}

		.pagination {
			display: flex;
			margin-top: 15px;
			justify-content: space-between;
			align-items: center;
		}
		
		.user-info{
			width: 100%;
			overflow: hidden;
			display: flex;
			
			.hd{
				flex: 0 0 auto;
				width: 50px;
				height: 50px;
				border-radius: 3px;
				overflow: hidden;
				margin-right: 5px;
			}
			.info{
				flex: 1 1 auto;
				overflow: hidden;
				
				display: flex;
				flex-direction: column;
				justify-content: center;
				
				.name{
					font-size: 14px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.phone{
					font-size: 12px;
					color: #999;
					margin-top: 0px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			
		}
	}
</style>
