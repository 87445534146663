<template>
	<div class="delete-notice">
		<el-dialog title="删人通知设置" :visible="visible" width="900px" :close-on-click-modal="false" @close="handleClose">

			<div class="content">
				<el-form label-width="160px">
					<el-form-item label="员工删人通知:">
						<el-switch v-model="isOpen"></el-switch>
						<div class="tip" v-if="!isOpen">开启后，你可以配置接收通知的员工，当员工把客户删除时，系统将在每天21:00开始给员工发送通知</div>
					</el-form-item>
					<el-form-item label="请选择接收通知的员工:" v-if="isOpen">
						<el-select v-model="value" multiple filterable
							default-first-option placeholder="请选择员工"
							style="width: 300px;margin-left: 5px;">
							<el-option v-for="item in employeeList" :key="item.Id" :label="item.EmployeeName" :value="item.Id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>


			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="handleSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>

</template>

<script>
	import config from '@/config';
	
	import {
		employeedeletecustomerreminderconfig,
		employeedeletecustomerreminderconfigsave,
		employeelistbystate
	} from '@/api/sv1.0.0.js';
	
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				
				isOpen: false,
				
				value: [],
				employeeList: []
			}
		},
		watch: {
			visible(){
				if (this.visible){
					this.getEmployeeList();
					this.init();
				}
			}
		},
		methods: {
			async getEmployeeList(){
				try{
					const res = await employeelistbystate({EmployeeState: 1, ShopId: 0});
					this.employeeList = res.Result;
				}catch(e){
					console.log(e)
				}
			},
			async init(){
				try{
					const res = await employeedeletecustomerreminderconfig();
					this.value = res.Result.EmployeeDeleteCustomerNoticeToEmployee;
					this.isOpen = res.Result.IsOpenEmployeeDeleteCustomerNotice;
				}catch(e){
					//TODO handle the exception
					console.log(e)
				}
			},
			handleClose() {
				this.$emit('close')
			},
			handleSubmit(){
				
				if (this.isOpen && !this.value.length){
					this.message('请选择接收通知的员工');
					return;
				}
				
				this.sureSubmit();
			},
			async sureSubmit(){
				this.loading = true;
				try{
					const res = await employeedeletecustomerreminderconfigsave({
						IsOpenEmployeeDeleteCustomerNotice: this.isOpen,
						EmployeeDeleteCustomerNoticeToEmployee: this.value
					})
					
					this.$emit('reload');
					this.handleClose();
					
				}catch(e){
					
				}finally{
					this.loading = false;
				}
			},
			message(str){
				this.$message.error(str)
			}
		}
	}
</script>

<style lang="less">
	.delete-notice {

		font-size: 14px;

		.content {
			max-height: 500px;
			overflow: auto;
		}

		.set {
			margin-top: 10px;
		}

		.tip {
			font-size: 12px;
			color: #999;
			line-height: 20px;
		}
	}
</style>
