<template>
	<div class="no-auth">
		<div v-if="isAuth">
			<slot></slot>
		</div>
		<el-card v-else>
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-b7dc93ee-4c84-4045-9d93-ee3f06fe4b35.png">
					<div class="info">
						<div class="tit">删人提醒</div>
						<div class="tip">当员工把客户微信删除时，将在该页面展示员工删人记录，并给企业管理员推送提醒。</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="handleUse">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/员工删人提醒</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-bf488200-dd86-4a47-9a17-d3cddd4c430b.png"></img>
				<div class="desc-title">2/管理员收到通知</div>
				<img style="display: block;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-39c7daa4-6b6d-4d57-aa63-d04d2d70d557.png"></img>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		qyweixinsuiteauthinfo
	} from "@/api/sv1.0.0";
	import config from "@/config/index";

	export default {
		components: {},
		data() {
			return {
				isAuth: true,
				goUrls: config.GO_URL,
			};
		},
		beforeMount() {
			this.getData();
		},
		methods: {
			async getData(callback) {
				try {
					const res = await qyweixinsuiteauthinfo();
					this.isAuth = res.Result.IsFinishQyWeixinSuiteStp;
				} catch (e) {
				}
			},
			handleUse() {
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				// let url = this.goUrls + headsUrls + '/saleChannel/qyWechat'
				// window.open(url)
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>
